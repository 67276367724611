import React, { useMemo } from 'react';

const ImageEnsure = ({
  src,
  className,
  alt,
  srcOnError = '/images/content/not-found-img.png',
}) => {
  const onError = (e) => {
    e.target.src = srcOnError;
  };

  const Image = useMemo(()=>{
    return <img className={className} src={src ? src.replace(".heic", ".webp") : "false"} onError={onError} alt={alt} data-src={src}/>;
  },[src])

  return Image;
};

export default ImageEnsure;
